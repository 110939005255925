<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit COD</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm" class="form text-center">
            <h3>Carrier status</h3>
            <status-datatable
              :statusData="carrier_statusData"
              :statusType="'carrier'"
              :addStatus="addStatus"
              :cod="$store.getters.getItemForAction"
              :editorData="formData"
              :removeStatus="removeStatus"
              :updateStatus="updateStatus"
            ></status-datatable>
            <hr />
            <h3>Customer status</h3>
            <status-datatable
              :statusData="customer_statusData"
              :statusType="'customer'"
              :addStatus="addStatus"
              :cod="$store.getters.getItemForAction"
              :editorData="formData"
              :removeStatus="removeStatus"
              :updateStatus="updateStatus"
            ></status-datatable>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import StatusDatatable from "./minicomp/StatusDatatable.vue";
import ApiService from "@/core/services/api.service";
export default {
  components: { StatusDatatable },
  name: "EditItem",
  props: ["refresher", "loader", "parents"],
  data: () => ({
    dialog: false,
    formData: {
      system_id: null,
      carrier: [],
      customer: [],
    },
    statusForCustomer: [
      {
        index: 340,
        text: "Pending",
      },
      {
        index: 350,
        text: "Confirmed",
      },
      {
        index: 360,
        text: "Remitted",
      },
      {
        index: 370,
        text: "Refunded",
      },
    ],
    statusForCarrier: [
      {
        index: 300,
        text: "Pending",
      },
      {
        index: 310,
        text: "Confirmed",
      },
      {
        index: 320,
        text: "Collected",
      },
      {
        index: 330,
        text: "Refunded",
      },
    ],
  }),
  // beforeMount() {
  //   this.resetEditForm();
  // },
  methods: {
    convertToFormData() {
      return this.formData;
    },
    submitEditForm() {
      this.loader(true);
      let data = this.convertToFormData();
      ApiService.post("/cod/cod/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Status data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    resetEditForm() {
      // console.log("look to this -< >- ", this.$store.getters.getItemForAction.systemId.detail);
      this.formData = {
        customer: this.$store.getters.getItemForAction.systemId.detail
          .customer_statuses
          ? this.$store.getters.getItemForAction.systemId.detail
              .customer_statuses.value
          : [],
        carrier: this.$store.getters.getItemForAction.systemId.detail
          .carrier_statuses
          ? this.$store.getters.getItemForAction.systemId.detail
              .carrier_statuses.value
          : [],
        system_id:
          this.$store.getters.getItemForAction.systemId.detail.system_id.value,
      };
    },
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetEditForm();
    },
    // "addStatus", "removeStatus", "updateStatus", "statusData", "cod"
    addStatus(cod, status, statusType) {
      //TODO: add status to carrier_statuses / customer_statuses according to statusType
      // console.log("add status -> ", status, statusType);
      if (statusType == "customer") {
        this.formData.customer.push(status);
        this.formData.customer.sort((a, b) => a.status_id - b.status_id);
      } else {
        this.formData.carrier.push(status);
        this.formData.carrier.sort((a, b) => a.status_id - b.status_id);
      }
    },
    updateStatus(cod, statusOld, status, statusType) {
      // console.log("update data", statusOld, status, statusType);
      if (statusType == "customer") {
        const index = this.formData.customer.indexOf(statusOld);

        if (index > -1) {
          this.formData.customer.splice(index, 1);
          this.formData.customer.push(status);
          this.formData.customer.sort((a, b) => a.status_id - b.status_id);
        } else {
          Swal.fire({
            title: "Error",
            text: `Can not find status with ${status.label}!`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        const index = this.formData.carrier.indexOf(statusOld);

        if (index > -1) {
          this.formData.carrier.splice(index, 1);
          this.formData.carrier.push(status);
          this.formData.carrier.sort((a, b) => a.status_id - b.status_id);
        } else {
          Swal.fire({
            title: "Error",
            text: `Can not find status with ${status.label}!`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      }
    },
    removeStatus(status, statusType) {
      // console.log('remove status ->', status );
      if (statusType == "customer") {
        const index = this.formData.customer.indexOf(status);
        if (index > -1) {
          this.formData.customer.splice(index, 1);
        } else {
          Swal.fire({
            title: "Error",
            text: `Can not find status with ${status.label}!`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        const index = this.formData.carrier.indexOf(status);
        if (index > -1) {
          this.formData.carrier.splice(index, 1);
        } else {
          Swal.fire({
            title: "Error",
            text: `Can not find status with ${status.label}!`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      }
    },
  },
  computed: {
    carrier_statusData: function () {
      return this.formData.carrier ? this.formData.carrier : [];
    },
    customer_statusData: function () {
      return this.formData.customer ? this.formData.customer : [];
    },
  },
};
</script>
