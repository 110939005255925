<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <button
        type="button"
        class="btn btn-info px-5 py-3 ls1"
        v-bind="attrs"
        v-on="on"
      >
        Add status
      </button>
    </template>
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add Status</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <v-form class="py-4">
          <v-text-field
            label="Rate"
            type="number"
            @change="() => validateMinValue('rate', 0, 'status')"
            v-model="status.rate"
            clearable
            outlined
            :error-messages="rateErrors"
            @input="$v.status.rate.$touch()"
            @blur="$v.status.rate.$touch()"
          ></v-text-field>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="status.date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                outlined
                :error-messages="dateErrors"
                @input="$v.status.date.$touch()"
                @blur="$v.status.date.$touch()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="status.date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            :items="
              statusType == 'customer' ? statusForCustomer : statusForCarrier
            "
            item-text="text"
            item-value="index"
            label="Status"
            clearable
            outlined
            v-model="status.status_id"
            :error-messages="status_idErrors"
            @input="$v.status.status_id.$touch()"
            @blur="$v.status.status_id.$touch()"
          ></v-select>
          <v-textarea
            label="Description"
            v-model="status.description"
            clearable
            outlined
          ></v-textarea>
        </v-form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetStatusData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="addNewStatus"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddStatus",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    status: {
      status_id: { required, numeric },
      rate: { required, numeric },
      date: { required },
    },
  },
  props: [
    "addStatus",
    "statusForCarrier",
    "statusForCustomer",
    "validateStatus",
    "cod",
    "statusType",
  ],
  data: () => ({
    menu: false,
    dialog: false,
    status: {
      status_id: null,
      description: null,
      rate: null,
      date: null,
      label: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.status[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.status[fieldName]) {
        !vueObj.$v.status[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.status[fieldName]) {
        !vueObj.$v.status[fieldName].url && errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.status[fieldName]) {
        !vueObj.$v.status[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.status[fieldName]) {
        !vueObj.$v.status[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    validator() {
      return this.validateStatus({ ...this.status }, this.statusType);
      // return true;
    },
    addNewStatus() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let isValid = this.validator();
        if (isValid) {
          if (this.statusType == "customer") {
            this.status.label = this.statusForCustomer.filter(
              (status) => status.index == this.status.status_id
            )[0].text;
          } else {
            this.status.label = this.statusForCarrier.filter(
              (status) => status.index == this.status.status_id
            )[0].text;
          }
          this.addStatus(this.cod, { ...this.status }, this.statusType);
          this.dialog = false;
          this.resetStatusData();
        } else {
          Swal.fire({
            title: "Error",
            text: "Data is invalid!",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          // console.log(this.status);
        }
      }
    },
    resetStatusData() {
      this.$v.$reset();

      this.isDataValid = false;
      this.status = {
        status_id: null,
        description: null,
        rate: null,
        date: null,
      };
    },
  },
  computed: {
    status_idErrors() {
      return this.handleFormValidation("status_id", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    dateErrors() {
      return this.handleFormValidation("date", this);
    },
  },
};
</script>
