<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :add-component="componentForAdding"
      :edit-component="componentForEditing"
      :upload-component="componentForUpload"
      :generate-component="componentForGenerate"
      :delete_url="delete_url"
      :urls="urls"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
// import AddItem from "@/own/components/finance/cod/AddItem";
import EditItem from "@/own/components/finance/cod/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/cod.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import Upload from "@/own/components/finance/cod/Upload";
import Generate from "@/own/components/finance/cod/Generate.vue";

export default {
  name: "COD",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: null,
    componentForUpload: null,
    componentForGenerate: Generate,
    delete_url: null,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getCODTableFilters",
      getTableState: "getCODTableState",
      getTableData: "getCODTableData",
      getTableHeaders: "getCODTableHeaders",
      getTableProperties: "getCODTableProperties",
      getTableExportUrl: "getCODExportUrl",
      getTablePermissions: "getCODTablePermissions",
      getUploadType: "getCODUploadType",
    },
    urls: {
      delete: null,
      activate: null,
      status: null,
      generate: "/authorizations/apps/generate",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
